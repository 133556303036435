import Box from "@/design-system/Box";
import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import { FC } from "react";
import styled from "styled-components";
import { StyledTextField } from "./textfield.style";
import { TextFieldProps } from "./types";

const IconBox = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  align-items: center;
  left: 14px;
  height: 100%;
`;

const TextField: FC<TextFieldProps> = ({
  error,
  errorName,
  mb,
  size,
  placeholder,
  placeholderColor,
  as,
  icon,
  disabled,
  onFocus,
  className,
  forwardedRef,
  ...rest
}) => {
  const Icon: any = () => icon;

  return (
    <Box mb={mb || theme.spacing(4)} width={"100%"}>
      <div style={{ width: "100%" }}>
        <StyledTextField
          as={as}
          size={size}
          icon={icon}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          onFocus={onFocus}
          disabled={disabled}
          className={className}
          ref={forwardedRef}
          {...rest}
        />

        {icon && (
          <IconBox>
            <Icon />
          </IconBox>
        )}
      </div>

      {error && (
        <Typography mt={"10px"} varient="captionl" color={"error.500"} as="p">
          {errorName}
        </Typography>
      )}
    </Box>
  );
};

export default TextField;
