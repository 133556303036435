import { theme } from "@/theme";
import styled, { css } from "styled-components";
import { space } from "styled-system";

interface StyledTextFieldProps {
  size: "md" | "lg";
  icon: boolean;
}

export const StyledTextField = styled.input<StyledTextFieldProps>`
  ${space}
  border: 1px solid ${theme.colors.gray[400]};
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: Inter;

  ${({ size, icon }) =>
    size === "lg"
      ? css`
          min-height: 56px;
          padding-left: ${() => (icon ? "42px" : "14px")};
          padding-right: 24px;
        `
      : size === "md"
      ? css`
          min-height: 40px;
          padding-left: ${icon ? "38px" : "14px"};
          padding-right: 14px;
        `
      : css`
          min-height: 40px;
          padding-left: ${icon ? "38px" : "14px"};
          padding-right: 14px;
          @media (min-width: ${theme.breakpoints.lg}) {
            min-height: 56px;
            padding-left: ${icon ? "42px" : "24px"};
            padding-right: 24px;
          }
        `}

  ${({ disabled }: any) =>
    !disabled &&
    css`
      &:active,
      &:focus {
        border: 1px solid ${theme.colors.primary[500]};
        outline: 0;
      }
      &:hover {
        border: 1px solid ${theme.colors.primary[500]};
      }
    `}

  ${({ error }: any) =>
    error && {
      border: `1px solid ${theme.colors.error[300]}`,
    }}
  ${({ disabled }: any) =>
    disabled && {
      border: `1px solid ${theme.colors.gray[400]}`,
      backgroundColor: theme.colors.gray[50],
    }}


  ${({ placeholderColor }: any) =>
    placeholderColor &&
    css`
      &::placeholder {
        color: ${placeholderColor};
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${placeholderColor};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${placeholderColor};
      }
    `}
`;
