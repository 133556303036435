import { ThemeBreakpoints } from "@/types";
import styled, { css, CSSProperties } from "styled-components";
import {
  color,
  flex,
  FlexProps,
  LayoutProps,
  space,
  typography,
  TypographyProps,
} from "styled-system";

import { ColorProps, SpaceProps } from "styled-system";

export interface CustomTypographyProps
  extends TypographyProps,
    SpaceProps,
    ColorProps,
    FlexProps,
    LayoutProps {
  as?: any;
  style?: CSSProperties;
  children?: React.ReactNode;
  mdVarient?: TypographyVarient;
  mdWeightVarient?: TypographyWeightVarient;
  className?: string;
  varient?: TypographyVarient;
  weightVarient?: TypographyWeightVarient;
  href?: string;
  onClick?: () => void;
}

export type TypographyWeightVarient =
  | "regular"
  | "medium"
  | "semibold"
  | "bold";

export type TypographyVarient =
  | "displayxl"
  | "displayl"
  | "displays"
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "heading6"
  | "subheadingl"
  | "subheadings"
  | "bodyl"
  | "bodys"
  | "bodyxs"
  | "captionl"
  | "captions"
  | "labell"
  | "labels";

const typeVariant = (
  varient: TypographyVarient,
  breakpoint?: ThemeBreakpoints
) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return varient === "displayxl"
      ? "4.625rem"
      : varient === "displayl"
      ? "3.875rem"
      : varient === "displays"
      ? "3rem"
      : varient === "heading1"
      ? "2.5rem"
      : varient === "heading2"
      ? "2.25rem"
      : varient === "heading3"
      ? "2rem"
      : varient === "heading4"
      ? "1.75rem"
      : varient === "heading5"
      ? "1.5rem"
      : varient === "heading6"
      ? "1.375rem"
      : varient === "subheadingl"
      ? "1.25rem"
      : varient === "subheadings"
      ? "1.125rem"
      : varient === "bodyl"
      ? "1.125rem"
      : varient === "bodys"
      ? "1rem"
      : varient === "bodyxs"
      ? "0.875rem"
      : varient === "captionl"
      ? "0.75rem"
      : varient === "captions"
      ? "0.625rem"
      : varient === "labell"
      ? "1rem"
      : varient === "labels"
      ? "0.75rem"
      : "1rem";
  }
  return varient === "displayxl"
    ? "2.5rem"
    : varient === "displayl"
    ? "2.25rem"
    : varient === "displays"
    ? "2rem"
    : varient === "heading1"
    ? "1.875rem"
    : varient === "heading2"
    ? "1.625rem"
    : varient === "heading3"
    ? "1.5rem"
    : varient === "heading4"
    ? "1.375rem"
    : varient === "heading5"
    ? "1.25rem"
    : varient === "heading6"
    ? "1.125rem"
    : varient === "subheadingl"
    ? "1.125rem"
    : varient === "subheadings"
    ? "1rem"
    : varient === "bodyl"
    ? "1rem"
    : varient === "bodys"
    ? "0.875rem" // 1rem
    : varient === "bodyxs"
    ? "0.75rem" // 0.875rem
    : varient === "captionl"
    ? "0.75rem"
    : varient === "captions"
    ? "0.625rem"
    : varient === "labell"
    ? "0.875rem"
    : varient === "labels"
    ? "0.75rem"
    : "1rem";
};

const typeLineHeight = (
  varient: TypographyVarient,
  breakpoint?: ThemeBreakpoints
) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return varient === "displayxl"
      ? "5.5rem"
      : varient === "displayl"
      ? "4.5rem"
      : varient === "displays"
      ? "3.5rem"
      : varient === "heading1"
      ? "3rem"
      : varient === "heading2"
      ? "2.75rem"
      : varient === "heading3"
      ? "2.5rem"
      : varient === "heading4"
      ? "2.25rem"
      : varient === "heading5"
      ? "2rem"
      : varient === "heading6"
      ? "2rem"
      : varient === "subheadingl"
      ? "1.75rem"
      : varient === "subheadings"
      ? "1.5rem"
      : varient === "bodyl"
      ? "1.75rem"
      : varient === "bodys"
      ? "1.5rem"
      : varient === "bodyxs"
      ? "1.25rem"
      : varient === "captionl"
      ? "1rem"
      : varient === "captions"
      ? "1rem"
      : varient === "labell"
      ? "1.25rem"
      : varient === "labels"
      ? "1rem"
      : "1.5rem";
  }
  return varient === "displayxl"
    ? "3rem"
    : varient === "displayl"
    ? "2.75rem"
    : varient === "displays"
    ? "2.5rem"
    : varient === "heading1"
    ? "2.5rem"
    : varient === "heading2"
    ? "2.25rem"
    : varient === "heading3"
    ? "2rem"
    : varient === "heading4"
    ? "1.75rem"
    : varient === "heading5"
    ? "1.75rem"
    : varient === "heading6"
    ? "1.5rem"
    : varient === "subheadingl"
    ? "1.5rem"
    : varient === "subheadings"
    ? "1.5rem"
    : varient === "bodyl"
    ? "1.5rem"
    : varient === "bodys"
    ? "1.25rem"
    : varient === "bodyxs"
    ? "1rem"
    : varient === "captionl"
    ? "1rem"
    : varient === "captions"
    ? "1rem"
    : varient === "labell"
    ? "1.25rem"
    : varient === "labels"
    ? "1rem"
    : "1.25rem";
};

const typeFont = (
  varient: TypographyVarient
  // breakpoint?: ThemeBreakpoints
) => {
  return varient === "displayxl"
    ? "Figtree, sans-serif"
    : varient === "displayl"
    ? "Figtree, sans-serif"
    : varient === "displays"
    ? "Figtree, sans-serif"
    : varient === "heading1"
    ? "Figtree, sans-serif"
    : varient === "heading2"
    ? "Figtree, sans-serif"
    : varient === "heading3"
    ? "Figtree, sans-serif"
    : varient === "heading4"
    ? "Figtree, sans-serif"
    : varient === "heading5"
    ? "Figtree, sans-serif"
    : varient === "heading6"
    ? "Figtree, sans-serif"
    : varient === "subheadingl"
    ? "Figtree, sans-serif"
    : varient === "subheadings"
    ? "Figtree, sans-serif"
    : varient === "bodyl"
    ? "Inter"
    : varient === "bodys"
    ? "Inter"
    : varient === "bodyxs"
    ? "Inter"
    : varient === "captionl"
    ? "Inter"
    : varient === "captions"
    ? "Inter"
    : varient === "labell"
    ? "Inter"
    : varient === "labels"
    ? "Inter"
    : "Inter";
};

const typeWeightVariant = (weightVarient: TypographyWeightVarient) => {
  return weightVarient === "regular"
    ? 400
    : weightVarient === "medium"
    ? 500
    : weightVarient === "semibold"
    ? 600
    : weightVarient === "bold"
    ? 700
    : 400;
};

const typeLetterSpacing = (
  varient: TypographyVarient,
  breakpoint?: ThemeBreakpoints
) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return varient === "displayxl"
      ? "-0.02em"
      : varient === "displayl"
      ? "-0.02em"
      : varient === "displays"
      ? "-0.02em"
      : varient === "heading1"
      ? "-0.02em"
      : varient === "heading2"
      ? "-0.02em"
      : varient === "heading3"
      ? "0em"
      : varient === "heading4"
      ? "0em"
      : varient === "heading5"
      ? "0em"
      : varient === "heading6"
      ? "0em"
      : varient === "subheadingl"
      ? "0em"
      : varient === "subheadings"
      ? "0em"
      : varient === "bodyl"
      ? "0em"
      : varient === "bodys"
      ? "0em"
      : varient === "bodyxs"
      ? "0em"
      : varient === "captionl"
      ? "0em"
      : varient === "captions"
      ? "0em"
      : varient === "labell"
      ? "0.08em"
      : varient === "labels"
      ? "0.08em"
      : "0em";
  }
  return varient === "displayxl"
    ? "-0.02em"
    : varient === "displayl"
    ? "-0.02em"
    : varient === "displays"
    ? "0em"
    : varient === "heading1"
    ? "0em"
    : varient === "heading2"
    ? "0em"
    : varient === "heading3"
    ? "0em"
    : varient === "heading4"
    ? "0em"
    : varient === "heading5"
    ? "0em"
    : varient === "heading6"
    ? "0em"
    : varient === "subheadingl"
    ? "0em"
    : varient === "subheadings"
    ? "0em"
    : varient === "bodyl"
    ? "0em"
    : varient === "bodys"
    ? "0em"
    : varient === "bodyxs"
    ? "0em"
    : varient === "captionl"
    ? "0em"
    : varient === "captions"
    ? "0em"
    : varient === "labell"
    ? "0.08em"
    : varient === "labels"
    ? "0.08em"
    : "0em";
};

export const StyledDynamicTypography = styled.p<CustomTypographyProps>`
  ${space};
  ${flex};
  ${color};
  ${typography};
  font-size: ${(props) => typeVariant(props.varient!)};
  font-weight: ${(props) => typeWeightVariant(props.weightVarient!)};
  font-family: ${(props) => typeFont(props.varient!)};
  line-height: ${(props) => typeLineHeight(props.varient!)};
  letter-spacing: ${(props) => typeLetterSpacing(props.varient!)};
  ${({ varient }) =>
    varient === "labels" &&
    css`
      text-transform: uppercase;
    `};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => typeVariant(props.varient!, ThemeBreakpoints.md)};
    line-height: ${(props) =>
      typeLineHeight(props.varient!, ThemeBreakpoints.md)};
    letter-spacing: ${(props) =>
      typeLetterSpacing(props.varient!, ThemeBreakpoints.md)};
  }
`;

export const StyledDynamicBreakpointTypography = styled.p<CustomTypographyProps>`
  ${space};
  ${flex};
  ${color};
  ${typography};
  font-size: ${(props) => typeVariant(props.varient!)};
  font-weight: ${(props) => typeWeightVariant(props.weightVarient!)};
  font-family: ${(props) => typeFont(props.varient!)};
  line-height: ${(props) => typeLineHeight(props.varient!)};
  letter-spacing: ${(props) => typeLetterSpacing(props.varient!)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => typeVariant(props.mdVarient!)};
    font-weight: ${(props) => typeWeightVariant(props.mdWeightVarient!)};
    line-height: ${(props) => typeLineHeight(props.mdVarient!)};
    letter-spacing: ${(props) => typeLetterSpacing(props.mdVarient!)};

    font-size: ${(props) => typeVariant(props.mdVarient!, ThemeBreakpoints.md)};
    line-height: ${(props) =>
      typeLineHeight(props.mdVarient!, ThemeBreakpoints.md)};
    letter-spacing: ${(props) =>
      typeLetterSpacing(props.mdVarient!, ThemeBreakpoints.md)};
  }
`;
